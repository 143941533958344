@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,400;1,300&family=Lexend:wght@200;400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kumbh+Sans:wght@300;400;500;600;700;800&display=swap');

@keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    100% {
      background-position: 100% 50%;
    }
  }
  
  .animate-gradient {
    background-clip: text ;
    -webkit-background-clip: text;
    background-size: 300% 300%;
    animation: gradient 4s ease-in-out infinite;
  }

  .homepage-text .line{
    display: inline-block;
    overflow: hidden;
  }
  
 
  .about-text .word{
    opacity: 0.2;
  }
  
  .bg-red-800:hover {
    transform: translateY(-5px);
  }
  
  
  